import type { FC } from 'react';
import React from 'react';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import { Banner } from '@features/endorsement-changes-banner/components';
import { mdiInformationSlabCircleOutline, mdiSync } from '@mdi/js';

interface RestoreChangesBannerProps {
  isLoading: boolean;
  onRestore: () => Promise<void>;
}

export const RestoreChangesBanner: FC<RestoreChangesBannerProps> = ({ isLoading, onRestore }) => {
  return (
    <Banner>
      <IconMdi path={mdiInformationSlabCircleOutline} className="text-gray-700" size={0.8} />
      <p>You have hidden contract changes, which will not be included in the summary</p>
      <Button className="w-42 h-8 text-gray-700" onClick={onRestore} loading={isLoading}>
        Restore all changes
        <IconMdi path={mdiSync} />
      </Button>
    </Banner>
  );
};
