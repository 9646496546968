import { toast } from 'react-toastify';
import type { EndorsementStoreSlice, SliceType } from '@ContractBuilder/store/types';
import { summaryBlockCreateSchema, summaryBlockUpdateSchema } from '@domain/schemas/endorsement-summary.schema';
import { ApiPatchOperation } from '@root/@types/payloads';
import { patchEndorsementSummary } from '@src/mutations';

export const createEndorsementSlice: SliceType<EndorsementStoreSlice> = (set, get) => ({
  createSummaryBlock: async (payload) => {
    set({ isLoading: true });

    const operation = { operation: ApiPatchOperation.CreateSummaryBlock } as const;
    const validationResult = summaryBlockCreateSchema.safeParse({
      ...payload,
      ...operation,
    });

    if (!validationResult.success) {
      set({ isLoading: false });
      toast.error('Unable to create summary block');
      return;
    }

    const result = await patchEndorsementSummary({
      ...validationResult.data,
      ...operation,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission();
      get().highlightChangedBlock(result.id);
    }

    set({ isLoading: false });
  },
  deleteSummaryBlock: async (payload) => {
    set({ isLoading: true });

    const result = await patchEndorsementSummary({ ...payload, operation: ApiPatchOperation.DeleteSummaryBlock });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }

    set({ isLoading: false });
  },
  deleteSummarySection: async (payload) => {
    set({ isLoading: true });

    const result = await patchEndorsementSummary({ ...payload, operation: ApiPatchOperation.DeleteSummarySection });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }

    set({ isLoading: false });
  },
  updateSummaryBlock: async (payload) => {
    set({ loadingBlockId: payload.blockId });

    const operation = { operation: ApiPatchOperation.UpdateSummaryBlock } as const;
    const validationResult = summaryBlockUpdateSchema.safeParse({
      ...payload,
      ...operation,
    });

    if (!validationResult.success) {
      set({ isLoading: false, loadingBlockId: undefined });
      toast.error('Unable to update summary block');
      return;
    }

    const result = await patchEndorsementSummary({
      ...validationResult.data,
      ...operation,
    });

    if (result?.message === 'success') {
      await get().reloadSubmission();
      get().highlightChangedBlock(payload.blockId);
    }

    set({ loadingBlockId: undefined, isLoading: false });
  },
  hideBlockFromPreview: async (payload) => {
    set({ loadingBlockId: payload.blockId });
    const result = await patchEndorsementSummary({ ...payload, operation: ApiPatchOperation.HideBlockFromPreview });

    if (result?.message === 'success') {
      await get().reloadSubmission();
      get().highlightChangedBlock(payload.blockId);
    }

    set({ loadingBlockId: undefined, isLoading: false });
  },
  restoreAllBlocksHiddenFromPreview: async () => {
    set({ isLoading: true });

    const result = await patchEndorsementSummary({ operation: ApiPatchOperation.RestoreBlocksHiddenFromPreview });

    if (result?.message === 'success') {
      await get().reloadSubmission();
    }

    set({ isLoading: false });
  },
});
