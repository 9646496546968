import type { FC } from 'react';
import { useState } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { useImagesStore } from '@features/branding-edit/store/images.store';
import { uploadImage } from '@mutations/uploadImage';
import { getImagesPresignedUrl } from '@queries/getImagesPresignedUrl';

import { ImageUploadView } from './ImageUploadView';

const GENERIC_UPLOAD_ERROR = 'Error uploading image, please try again';

const acceptOnlyUniqueFiles = (existing: File[], incoming: File[]) => {
  const fileNames = existing.map((file) => file.name);
  const onlyNewFiles = incoming.filter((file) => !fileNames.includes(file.name));

  return [...existing, ...onlyNewFiles].sort((a, b) => a.name.localeCompare(b.name));
};

export interface ImageUploadControllerProps {
  isOpen: boolean;
  handleClose: () => void;
  refetchImageList: () => void;
}

export const ImageUploadController: FC<ImageUploadControllerProps> = ({ handleClose, refetchImageList }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const { updateStatus } = useImagesStore((s) => s);

  const handleFileChange = (newFiles: File[]) => {
    if (newFiles.length < 1) {
      return;
    }

    setFiles(acceptOnlyUniqueFiles(files, newFiles));
  };

  const handleFileRejection = (fileRejections: FileRejection[], _event: DropEvent) => {
    const errors = fileRejections
      .map(({ errors, file }) =>
        errors.map(
          ({ message, code }) =>
            `File rejected: ${file.name} - ${code === 'file-too-large' ? 'File is larger than 1MB' : message}
      `,
        ),
      )
      .flat();

    setErrors((existing) => [...existing, ...errors]);
  };

  const handleSubmit = async () => {
    setErrors([]);
    if (files.length > 0) {
      const file = files[0];

      const response = await getImagesPresignedUrl(file.name, file.size, file.type);

      if (!response || !response?.id || !response?.url) {
        setErrors([GENERIC_UPLOAD_ERROR]);
        return;
      }

      try {
        await uploadImage(response.url, { file }, response.fields);
      } catch (error) {
        setErrors([GENERIC_UPLOAD_ERROR]);
        return;
      }

      updateStatus(response.id, 'ready');

      onSuccess();
    }
  };

  const onSuccess = () => {
    refetchImageList();
    setFiles([]);
    setErrors([]);
    handleClose();
  };

  const handleCancel = () => {
    setFiles([]);
    setErrors([]);
    handleClose();
  };

  return (
    <Modal open className="max-w-[600px]" onClose={handleCancel} title="Upload image">
      <div className="flex flex-col">
        <ImageUploadView
          onChange={handleFileChange}
          onFileRejection={handleFileRejection}
          files={files}
          errors={errors}
        />
        <div className="inline-flex items-start justify-start gap-3 self-stretch">
          <div className="flex shrink grow basis-0 items-start justify-end gap-3">
            <Button kind="secondary" className="h-9 min-w-[80px]" onClick={handleCancel}>
              Cancel
            </Button>
            <Button kind="primary" className="h-9 min-w-[80px]" isDisabled={files.length === 0} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
