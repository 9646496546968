import type { Branding } from '@domain/schemas/branding.schema';
import {
  brandingCreateSchema,
  brandingUpdateContentSchema,
  brandingUpdateNameSchema,
  brandingUpdateStatusSchema,
} from '@domain/schemas/branding.schema';
import { createBranding } from '@mutations/createBranding';
import { updateBranding } from '@mutations/updateBranding';
import { isEqual } from 'lodash-es';
import { create } from 'zustand';

import { getAuthorship } from '../utils/get-authorship';

export enum BrandingEditorKey {
  Header = 'branding-header',
  Footer = 'branding-footer',
}

interface BrandingStore {
  originalBranding: Partial<Branding> | null;
  branding: Partial<Branding> | null;
  isLoading: boolean;
  dateInfo: string | null;
  form: {
    isDirty: boolean;
    editorKey: BrandingEditorKey | null;
  };
  reset: () => void;
  setBranding: (branding: Partial<Branding>) => void;
  setLoading: (isLoading: boolean) => void;
  saveChanges: () => Promise<{ success: boolean; error?: string }>;
  saveNameChange: (name: string) => Promise<{ success: boolean; error?: string }>;
  handleChange: (key: keyof Branding) => (value: string) => void;
  updateStatus: (status: Branding['status']) => void;
  restore: () => void;
  setFormEditorKey: (editorKey: BrandingEditorKey | null) => void;
  duplicateBranding: (sourceId: string) => Promise<{ success: boolean; error?: string; id?: string }>;
}

export const useBrandingStore = create<BrandingStore>((set, get) => ({
  originalBranding: null,
  branding: null,
  isLoading: false,
  dateInfo: null,
  form: {
    editorKey: null,
    isDirty: false,
  },
  reset: () => {
    return set({
      dateInfo: null,
      isLoading: false,
      originalBranding: null,
      branding: null,
      form: {
        editorKey: null,
        isDirty: false,
      },
    });
  },
  setBranding: (branding) => {
    const cleanedBranding = {
      ...branding,
      header_content: branding.header_content ?? '',
      footer_content: branding.footer_content ?? '',
    };
    set({ branding: cleanedBranding, originalBranding: cleanedBranding, dateInfo: getAuthorship(branding) });
  },
  setLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  setFormEditorKey: (editorKey: BrandingEditorKey | null) => {
    set((state) => ({ form: { ...state.form, editorKey } }));
  },
  handleChange: (key) => (value: string) => {
    const original = get().originalBranding;
    const branding = get().branding;
    const newBranding = { ...branding, [key]: value };
    const isDirty = !isEqual(original, newBranding);
    set((state) => ({
      branding: newBranding,
      form: { ...state.form, isDirty },
    }));
  },
  restore: () => {
    set((s) => ({ isLoading: false, form: { isDirty: false, editorKey: null }, branding: s.originalBranding }));
  },
  saveChanges: async () => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return { success: false, error: 'Branding not found' };
    }

    const payload = brandingUpdateContentSchema.safeParse(branding);
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    await updateBranding(payload.data, branding.id!);
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
    return { success: true };
  },
  duplicateBranding: async (sourceId) => {
    set({ isLoading: true });
    const branding = get().branding;

    const payload = brandingCreateSchema.safeParse({ ...branding, clone_from_id: sourceId });
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    const id: string = (await createBranding(payload.data)).id;
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });

    return { success: true, id };
  },
  saveNameChange: async (name) => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return { success: false, error: 'Branding not found' };
    }

    const payload = brandingUpdateNameSchema.safeParse({ name: name });
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    await updateBranding(payload.data, branding.id!);
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
    get().setBranding({ ...branding, name });
    return { success: true };
  },
  updateStatus: async (status) => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return { success: false, error: 'Branding not found' };
    }

    const payload = brandingUpdateStatusSchema.safeParse({ status });
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    await updateBranding(payload.data, branding.id!);

    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
    get().setBranding({ ...branding, status });
    return { success: true };
  },
}));
